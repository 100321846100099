/* User consent */

.consent-statement-container {
    padding: 15px 0;
    background-color: #e6e6e6;
    margin-bottom: 15px;
}

.consent-statement-text {
    display: block;
    padding-right: 50px;
    position: relative;
    min-height: 40px;
    font-size: 12px;
}

.consent-statement-close {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #e6e6e6;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}

.consent-statement-close:hover {
    opacity: 1;
}

.consent-statement-close:before, .consent-statement-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
}

.consent-statement-close:before {
    transform: rotate(45deg);
}

.consent-statement-close:after {
    transform: rotate(-45deg);
}

.consent-statement-close-container {
    height: 60px;
    width: 90px;
}
